import React from 'react';
import '../css/CartHeader.css';

const CartHeader = () => {
  return (
    <div className="cart-header">
      <h1>The Greatest Shopping Cart</h1>
    </div>
  );
}

export default CartHeader;
